import React, { Component, Fragment } from 'react';
import jmap from 'jmap.js';
import PropTypes from 'prop-types';
import MapUiKit from '@jibestream-dev/jmap-mapui-kit';
import NavigationKit from '@jibestream-dev/jmap-navigation-kit';
import { Button, CircularProgress } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import { calculateSeconds } from '../../utils/date';
import { changeFavicon, loadMapConfiguration } from '../../utils/malls';
import { CONTAINER_MAP } from '../../constants/mall';
import {
  MALL_ID_NOT_FOUND,
  EXTERNAL_ID_NOT_FOUND
} from '../../constants/messages';
import {
  highlight,
  getStore,
  changeFloor,
  initControllerMap,
  getDeviceByName,
  getWaypointFromDestination,
  highlightAndZoom,
  highlightAndZoomFromAmenity,
  initMapDevices,
  prepareMapForRender,
  zoom,
  getStoreFromAmenitie,
  getFloorsPages
} from '../../utils/jibestream';
import {
  sendEventStepOne,
  sendEventStepTwo,
  sendEventGeneratedPath,
  sendEventPathRepeat,
  sendEventMapError
} from '../../utils/googleAnalytics';
import { JibestreamContext } from '../../context/JibestreamContext';

import ModalError from './ModalError';
import './centre-map.scss';
import ButtonFloor from './components/ButtonFloors';
import ModalAnimations from './components/ModalAnimations';
import {
  STATUS_FLOOR,
  ESCALATOR,
  STAIRS,
  ELEVATOR,
  DURATION_ANIMATION_TRACE_PATH,
  TIME_TO_SHOW_THE_MODAL,
  TIME_TO_CLOSE_MODAL,
  TIME_TO_SHOW_NEXT_FLOOR
} from './constants';
import { MAP_UI_KIT, COLOR_SPINNER } from './styles';

class CentreMap extends Component {
  static contextType = JibestreamContext;

  constructor(props) {
    super(props);
    this.state = {
      showLevelButtons: false,
      activeFloor: 0,
      showInstructions: false,
      pathToStore: [],
      openModal: false,
      errorText: '',
      isAnimatingPath: false,
      setOpen: false,
      messageModal: false,
      moverId: 0,
      loadingMap: false,
      hasJBInformation: false,
      fullPath: false,
      isStatic: false,
      pageFloor: 0,
      floorsPerPage: 3,
      accesibleRoute: 100
    };

    this.informationMall = null;
    this.floorsPages = [];
    this.CONTROL = null;
    this.VENUE = null;
    this.CORE = null;
    this.HighlightedDestinations = null;
    this.DeviceKit = null;
    this.NavigationKit = null;
    this.MapUiKit = null;
    this.mapDevices = [];
    this.Destinations = null;
    this.pathAnimationTimeout = null;
    this.jmapLoaded = false;
    this.Instruccions = null;
    this.store = null;
    this.wayPointDestination = null;
    this.isAmenity = false;
    this.originStore = null;
    this.wayPointOrigin = null;
    this.originIsAmenity = false;
    this.activeUserTimer = null;
    this.userInactive = false;
    this.QR = window?.location?.pathname?.split('/')[3];
    this.step1 = null;
    this.initialDate = new Date();
    this.mounted = true;
  }

  static propTypes = {
    showPath: PropTypes.bool.isRequired,
    mall: PropTypes.any.isRequired,
    origin: PropTypes.any,
    destination: PropTypes.any,
    amenity: PropTypes.any,
    type: PropTypes.any,
    getJbInformation: PropTypes.any.isRequired,
    handleCloseModal: PropTypes.any,
    noDestination: PropTypes.any,
    destinationFloor: PropTypes.any
  };

  static defaultProps = {
    origin: undefined,
    handleCloseModal: undefined,
    destination: undefined,
    amenity: undefined,
    type: undefined,
    noDestination: undefined,
    destinationFloor: undefined
  };

  mapHolder = React.createRef();

  componentDidMount = async () => {
    try {
      this.mounted = true;
      const { mall, destination, showPath, amenity, type, origin } = this.props;
      const { jibestreamContext, setJibestreamContext } = this.context;
      let { configMap, configDeviceKit } = jibestreamContext;

      if (!configMap) {
        configMap = await loadMapConfiguration(mall);

        if (this.mounted) {
          setJibestreamContext({
            configDeviceKit,
            configMap
          });
        }
      }

      const { configMall, informationJibestream } = configMap;

      if (!configMall && this.mounted) {
        this.setState({
          openModal: true,
          errorText: MALL_ID_NOT_FOUND
        });
      } else {
        const {
          activeVenue,
          core,
          highlightedDestinations,
          destinations
        } = informationJibestream;

        const { floorsPerPage } = this.state;

        this.informationMall = configMall;
        this.floorsPages = getFloorsPages(configMall, floorsPerPage);
        this.VENUE = activeVenue;
        this.highlightedDestinations = highlightedDestinations;
        this.Destinations = destinations;
        this.CORE = core;
        changeFavicon(this.informationMall.favicon);
        this.CONTROL = initControllerMap(this.VENUE, this.mapHolder);

        // si no tiene valores de origen y destino se carga mapa estatico
        if (!destination && !origin) {
          this.LoadStaticMap(configMap.configMall);
        }

        const { isStatic } = this.state;
        const { noDestination, destinationFloor } = this.props;

        if (!isStatic) {
          if (destinationFloor !== undefined) {
            const filter = item => item.name === amenity;
            let externalId;

            if (
              amenity === 'Cajeros Estacionamientos' ||
              amenity === 'Cajeros Estacionamientos Efectivo'
            ) {
              const waypointAmenity = getStoreFromAmenitie(
                destinationFloor,
                this.VENUE
              );
              const { waypoint } = this.VENUE.getClosestAmenityToWaypoint(
                waypointAmenity,
                filter
              );

              externalId = waypoint.externalId;
              this.initMapDestinations(externalId);

              this.initMapUI();

              if (!configDeviceKit) {
                configDeviceKit = await initMapDevices(
                  this.CONTROL,
                  this.CORE,
                  this.mapDevices,
                  origin
                );

                if (this.mounted) {
                  setJibestreamContext({
                    configMap,
                    configDeviceKit
                  });
                }
              }

              if (configDeviceKit !== null) {
                const { deviceKit, devices } = configDeviceKit;

                this.DeviceKit = deviceKit;
                this.mapDevices = devices;
                this.drawPathFromDeviceToStore();
              } else if (type) {
                this.initMapOriginStore(origin);
                this.drawPathSelectedAmenity();
              }
            }
          } else {
            if (noDestination) {
              const dest = localStorage.getItem('map_externalId');

              this.initMapDestinations(dest);
            } else {
              if (amenity) {
                this.initMapDestinationsNoSelectable(amenity);
              }

              if (!amenity) {
                this.initMapDestinations(destination);
              }
            }

            this.initMapUI();

            if (showPath) {
              if (!configDeviceKit) {
                configDeviceKit = await initMapDevices(
                  this.CONTROL,
                  this.CORE,
                  this.mapDevices,
                  origin
                );

                if (this.mounted) {
                  setJibestreamContext({
                    configMap,
                    configDeviceKit
                  });
                }
              }

              if (configDeviceKit !== null) {
                const { deviceKit, devices } = configDeviceKit;

                this.DeviceKit = deviceKit;
                this.mapDevices = devices;
                this.drawPathFromDeviceToStore();
              } else if (amenity) {
                this.drawPathToCloseAmenity(amenity);
              } else if (type) {
                this.initMapOriginStore(origin);
                this.drawPathSelectedAmenity();
              } else {
                this.initMapOriginStore(origin);
                this.drawPathFromStoreToStore();
              }
            } else if (this.store && this.mounted) {
              this.setState({ showLevelButtons: true });
              this.highlightStore(true);
            }
          }
        }

        this.CONTROL.disableRotationGestures();
        this.CONTROL.showAllTextMapLabels();
        this.CONTROL.showAllImageMapLabels();
        this.CONTROL.applyDisplayModeToAllUnits();
      }
    } catch (err) {
      const { mall, origin, destination } = this.props;

      sendEventMapError(mall, origin, destination, err.message);
      // eslint-disable-next-line no-console
      console.error('centre-map.componentDidMount', err);
    }
  };

  setPageFloorFromActiveFloor = activeFloor => {
    this.floorsPages.forEach((pageFloor, index) => {
      if (pageFloor.filter(page => page.floor === activeFloor).length > 0) {
        this.setState({
          pageFloor: index
        });
      }
    });
  };

  setPageFloor = page => {
    this.setState({
      pageFloor: page
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isAnimatingPath, hasJBInformation, fullPath } = this.state;
    const { mall, getJbInformation, showPath } = this.props;

    if (prevState.hasJBInformation !== hasJBInformation) {
      clearTimeout(getJbInformation);
    }

    if (!isAnimatingPath && fullPath && showPath) {
      const seconds = calculateSeconds(this.initialDate, new Date());

      sendEventStepTwo(mall, seconds, this.QR);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  initMapDestinations = destination => {
    highlight(this.highlightedDestinations, this.CONTROL);
    this.store = getStore(this.Destinations, destination);

    if (!this.store) {
      const waypointAmenity = getStoreFromAmenitie(destination, this.VENUE);

      if (waypointAmenity) {
        this.isAmenity = true;
        this.store = waypointAmenity;
        this.wayPointDestination = waypointAmenity;
      }

      if (waypointAmenity) {
        this.isAmenity = true;
        this.store = waypointAmenity;
        this.wayPointDestination = waypointAmenity;
      }
    }

    if (this.store === undefined && this.mounted) {
      this.setState({
        openModal: true,
        errorText: `${EXTERNAL_ID_NOT_FOUND} el destino de la ruta`
      });
    }
  };

  LoadStaticMap = configMap => {
    this.initMapUI();
    this.setState({ showLevelButtons: true, isStatic: true, loadingMap: true });

    const config = {
      host: 'https://api.jibestream.com',
      auth: new jmap.core.Auth(configMap.clientId, configMap.secret),
      customerId: configMap.customerId,
      venueId: configMap.venueId,
      applyDisplayMode: true
    };

    this.CONTROL.setMapTransform(new jmap.Transform({ scale: 0 }));

    jmap.init(config);
  };

  initMapDestinationsFloor = origin => {
    const waypointAmenity = getStoreFromAmenitie(origin, this.VENUE);

    if (waypointAmenity) {
      this.storeIsAmenity = true;
      this.originStore = waypointAmenity;
      this.wayPointOrigin = waypointAmenity;
    }
  };

  initMapDestinationsNoSelectable = amenity => {
    const filter = item => item.name === amenity;
    const { origin } = this.props;

    if (
      amenity === 'Cajeros Estacionamientos' ||
      amenity === 'Cajeros Estacionamientos Efectivo'
    ) {
      // this.initMapDestinationsFloor(origin);
      this.initMapOriginStore(origin);
      const { waypoint } = this.VENUE.getClosestAmenityToWaypoint(
        this.originStore.waypoints[0],
        filter
      );

      localStorage.setItem('map_externalId', waypoint.externalId);

      if (waypoint) {
        this.isAmenity = true;
        this.store = waypoint;
        this.wayPointDestination = waypoint;
      }
    } else {
      this.initMapOriginStore(origin);

      const { waypoint } = this.VENUE.getClosestAmenityToWaypoint(
        this.originStore.waypoints[0],
        filter
      );

      if (waypoint) {
        this.isAmenity = true;
        this.store = waypoint;
        this.wayPointDestination = waypoint;
      }
    }
  };

  initMapOriginStore = originStore => {
    highlight(this.highlightedDestinations, this.CONTROL);
    this.originStore = getStore(this.Destinations, originStore);

    if (!this.originStore) {
      const waypointAmenity = getStoreFromAmenitie(originStore, this.VENUE);

      if (waypointAmenity) {
        this.storeIsAmenity = true;
        this.originStore = waypointAmenity;
        this.wayPointOrigin = waypointAmenity;
      }
    }

    if (this.originStore === undefined && this.mounted) {
      const langSet = localStorage.getItem('language');
      let lang = 'el origen de la ruta';

      if (langSet === 'en') {
        lang = 'the origin of the route';
      }

      if (langSet === 'pt') {
        lang = 'o origem da rota';
      }

      this.setState({
        openModal: true,
        errorText: `${EXTERNAL_ID_NOT_FOUND} ${lang}`
      });
    }
  };

  initMapUI = () => {
    const { origin, amenity } = this.props;

    jmap.dispatcher.subscribe('ready', () => {
      this.NavigationKit = new NavigationKit(this.CONTROL, {});
    });

    if (!origin && this.mounted) {
      this.setState({ loadingMap: true });
    }

    jmap.dispatcher.subscribe('JMAP_ENGINE_PARSING_START', () => {
      if (this.mounted) {
        this.setState({ loadingMap: true });
      }
    });
    jmap.dispatcher.subscribe('JMAP_ENGINE_PARSING_SUCCESS', () => {
      if (this.mounted) {
        this.setState({ loadingMap: true });
      }
    });

    if (
      amenity === 'Cajeros Estacionamientos' ||
      amenity === 'Cajeros Estacionamientos Efectivo'
    ) {
      this.setState({ loadingMap: true });
    }

    this.MapUiKit = new MapUiKit(this.CONTROL, { padding: [20, 20, 20, 20] });
    this.MapUiKit.renderZoomButtons(MAP_UI_KIT);
    this.CONTROL.setMaxScale(40);
  };

  highlightStore = focusStore => {
    const mapId = this.isAmenity
      ? highlightAndZoomFromAmenity(
          this.CONTROL,
          this.store,
          focusStore,
          this.VENUE
        )
      : highlightAndZoom(this.CONTROL, this.store, focusStore, this.VENUE);

    if (mapId !== false && this.mounted) {
      this.setState({ activeFloor: mapId });
      this.setPageFloorFromActiveFloor(mapId);
    }
  };

  drawPathFromDeviceToStore = () => {
    const { origin } = this.props;
    const deviceOrigin = getDeviceByName(origin, this.mapDevices);

    if (deviceOrigin === undefined && this.mounted) {
      const langSet = localStorage.getItem('language');
      let lang = 'el origen de la ruta';

      if (langSet === 'en') {
        lang = 'the origin of the route';
      }

      if (langSet === 'pt') {
        lang = 'o origem da rota';
      }

      this.setState({
        openModal: true,
        errorText: `${EXTERNAL_ID_NOT_FOUND} ${lang}`
      });

      return;
    }

    const storeWaypoint = this.wayPointDestination
      ? this.wayPointDestination
      : getWaypointFromDestination(this.store, this.VENUE);

    const deviceWaypoint = this.DeviceKit.getWaypointFromDevice(deviceOrigin);

    this.highlightStore();
    this.drawPath(deviceWaypoint, storeWaypoint);
  };

  drawPathSelectedAmenity = () => {
    this.highlightStore();
    this.drawPath(this.originStore.waypoints[0], this.store);
  };

  drawPathFromStoreToStore = accesibleSwitch => {
    const originStoreWaypoint = this.wayPointDestination
      ? this.wayPointDestination
      : getWaypointFromDestination(this.originStore, this.VENUE);

    const storeWaypoint = this.wayPointOrigin
      ? this.wayPointOrigin
      : getWaypointFromDestination(this.store, this.VENUE);

    this.highlightStore();
    this.drawPath(originStoreWaypoint, storeWaypoint, accesibleSwitch);
  };

  drawPathToCloseAmenity = amenity => {
    const filter = item => item.name === amenity;
    const { origin } = this.props;

    this.initMapOriginStore(origin);
    const { waypoint } = this.VENUE.getClosestAmenityToWaypoint(
      this.originStore.waypoints[0],
      filter
    );

    if (waypoint) {
      this.isAmenity = true;
      this.store = waypoint;
      this.wayPointDestination = waypoint;
    }

    this.highlightStore();
    this.drawPath(this.originStore.waypoints[0], this.store);
  };

  handleChangeFloor = mapId => () => {
    const { activeFloor } = this.state;
    const { showPath } = this.props;

    if (activeFloor === mapId) {
      return;
    }

    changeFloor(mapId, showPath, this.CONTROL, this.VENUE);

    if (this.mounted) {
      this.setState({ activeFloor: mapId });
      this.setPageFloorFromActiveFloor(mapId);
    }
  };

  drawPath = (_from, _to, accesibleSwitch) => {
    const { error, path } = prepareMapForRender(
      _from,
      _to,
      this.CONTROL,
      accesibleSwitch
    );

    if (error.length > 0 && this.mounted) {
      this.setState({
        openModal: true,
        errorText: error
      });

      return;
    }

    const { mall, origin, destination } = this.props;

    sendEventGeneratedPath(mall, origin, destination);

    // Set the path to use it again
    // and show level buttons
    if (Array.isArray(path) && this.mounted) {
      this.setState({
        pathToStore: path,
        showLevelButtons: true,
        isAnimatingPath: true,
        hasJBInformation: true,
        fullPath: true
      });

      if (this.step1) {
        clearTimeout(this.step1);
      }

      const seconds = calculateSeconds(this.initialDate, new Date());

      this.step1 = setTimeout(() => {
        sendEventStepOne(mall, seconds, this.QR);
      }, 5000);
    }

    // animate the static path drawn before
    this.animatePathsAndChangeFloors(path, 0);
  };

  handleFloorLevel = (currentLevel, nextLvel, pathToStore, index, moverId) => {
    const messageModal = currentLevel < nextLvel;

    if (this.mounted) {
      this.setState({ messageModal, moverId });
    }

    this.handleOpenModal();
    setTimeout(() => this.handleCloseModal(), TIME_TO_CLOSE_MODAL);
    setTimeout(
      () => this.animatePathsAndChangeFloors(pathToStore, index + 1),
      TIME_TO_SHOW_NEXT_FLOOR
    );
  };

  handleOpenModal = () =>
    this.mounted ? this.setState({ setOpen: true }) : null;

  handleCloseModal = () =>
    setTimeout(
      () => (this.mounted ? this.setState({ setOpen: false }) : null),
      2000
    );

  // pathToStore[]: array of paths between waypoints
  // index: current path
  animatePathsAndChangeFloors = (pathToStore, index) => {
    // if we still have paths to animate
    if (pathToStore.length > index) {
      // Set current activeFloor
      if (this.mounted) {
        this.setState({ activeFloor: pathToStore[index].mapId });
        this.setPageFloorFromActiveFloor(pathToStore[index].mapId);
      }

      // get the current map(level)
      const map = this.VENUE.maps.getById(pathToStore[index].mapId);
      const path = this.CONTROL.getShapesInLayer('Wayfinding-Path', map);
      const bounds = this.CONTROL.getBoundsFromShapes(path);

      // show the map(level)
      this.CONTROL.showMap(map);

      // make sure we see the full path
      zoom(bounds, 1, 150, this.CONTROL);
      // Animate initial path for 5 seconds
      path[0].animate(DURATION_ANIMATION_TRACE_PATH);

      // divido las intrucciones por piso, subir a las escaleras cuenta
      // devido la duracion de la animacion con el numero de instrucciones del piso
      if (this.pathAnimationTimeout) {
        clearTimeout(this.pathAnimationTimeout);
      }

      // Set the next floor to start in 7 seconds
      this.pathAnimationTimeout = setTimeout(() => {
        const level = pathToStore.map(a => a.seq);
        const options = [ESCALATOR, STAIRS, ELEVATOR];

        if (options.includes(pathToStore[index].mover.typeName)) {
          const currentLevel = this.CONTROL.showMap(map).currentFloor.level;
          const nextLvel = level[level.length - 1];
          const { moverId } = pathToStore[index].mover;

          this.handleFloorLevel(
            currentLevel,
            nextLvel,
            pathToStore,
            index,
            moverId
          );
        } else {
          this.animatePathsAndChangeFloors(pathToStore, index + 1);
        }
      }, TIME_TO_SHOW_THE_MODAL);
    } else if (this.mounted) {
      // there are no more paths
      this.setState({ isAnimatingPath: false });
    }
  };

  handleRepeatPathAnimation = () => {
    const { pathToStore } = this.state;

    if (this.mounted) {
      this.setState({ isAnimatingPath: true });
    }

    const { mall, origin, destination } = this.props;

    sendEventPathRepeat(mall, origin, destination);

    this.animatePathsAndChangeFloors(pathToStore, 0);
  };

  handleIsLevelActive = mapId => {
    const { activeFloor } = this.state;

    if (activeFloor === mapId) {
      return STATUS_FLOOR;
    }

    return '';
  };

  handleIsButtonDisabled = floorId => {
    const { isAnimatingPath, pathToStore, isStatic } = this.state;

    if (isStatic) {
      return false;
    }

    // if we are animating -> disable level buttons
    if (isAnimatingPath) {
      return true;
    }
    // if the level is not in the path -> disable that button

    const isNotOnPath =
      pathToStore.find(f => f.mapId === floorId) === undefined;

    return isNotOnPath;
  };

  handleCloseError = () => {
    const { handleCloseModal } = this.props;

    if (this.mounted) {
      this.setState({
        openModal: false,
        loadingMap: true
      });

      if (handleCloseModal) {
        handleCloseModal();
      }
    }
  };

  handleChangeAccesibleRoute = async () => {
    const { accesibleRoute } = this.state;
    const accesibleSwitch = accesibleRoute === 100 ? 0 : 100;

    this.setState({
      accesibleRoute: accesibleSwitch
    });
    this.drawPathFromStoreToStore(accesibleSwitch);
  };

  render() {
    const {
      showLevelButtons,
      isAnimatingPath,
      showInstructions,
      openModal,
      errorText,
      setOpen,
      moverId,
      messageModal,
      loadingMap,
      pageFloor,
      accesibleRoute
    } = this.state;

    const { showPath } = this.props;
    const showPaginationFloor =
      this.informationMall &&
      this.informationMall.buttonsFloors &&
      this.informationMall.buttonsFloors.length > 5;

    const activeRepite =
      accesibleRoute === 100 ? '' : 'button-accesible-active';
    const disableRepite = isAnimatingPath ? 'button-accesible-disabled' : '';

    return (
      <div id='map-cont' className='Map-container'>
        <ModalError
          open={openModal}
          text={errorText}
          onHandleCloseError={this.handleCloseError}
        />
        <ModalAnimations
          setOpen={setOpen}
          onHandleClose={this.handleClose}
          moverId={moverId}
          messageModal={messageModal}
        />
        {isAnimatingPath && showInstructions && (
          <div className='path-instructions'>
            <div className='image'> imagen</div>
            <div className='text'>
              <Trans i18nKey='UP_STAIRS' />
            </div>
          </div>
        )}
        {showLevelButtons && (
          <Fragment>
            <div className='floor-levels'>
              {showPaginationFloor ? (
                <Fragment>
                  <Button
                    className='small'
                    onClick={() => this.setPageFloor(pageFloor + 1)}
                    disabled={pageFloor === this.floorsPages.length - 1}
                  >
                    <IoIosArrowUp size={25} />
                  </Button>
                  {this.floorsPages[pageFloor].map(items => (
                    <ButtonFloor
                      key={items.floor}
                      items={items}
                      onHandleChangeFloor={this.handleChangeFloor}
                      onHandleIsLevelActive={this.handleIsLevelActive}
                      onHandleIsButtonDisabled={this.handleIsButtonDisabled}
                    />
                  ))}
                  <Button
                    className='small'
                    onClick={() => this.setPageFloor(pageFloor - 1)}
                    disabled={pageFloor === 0}
                  >
                    <IoIosArrowDown size={25} />
                  </Button>
                </Fragment>
              ) : (
                this.informationMall.buttonsFloors.map(items => (
                  <ButtonFloor
                    key={items.floor}
                    items={items}
                    onHandleChangeFloor={this.handleChangeFloor}
                    onHandleIsLevelActive={this.handleIsLevelActive}
                    onHandleIsButtonDisabled={this.handleIsButtonDisabled}
                  />
                ))
              )}
            </div>
            {showPath && (
              <Fragment>
                <div
                  className={
                    isAnimatingPath
                      ? `accesible-route-button  ${disableRepite}`
                      : `accesible-route-button ${activeRepite} `
                  }
                >
                  <div
                    disabled={isAnimatingPath}
                    onClick={this.handleChangeAccesibleRoute}
                    className='button-accesible'
                  >
                    <span className='txt-icono'>
                      <Trans i18nKey='ACCESIBLE_ROUTE' />
                    </span>
                  </div>
                </div>
                <div className='repeat-route-button'>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={isAnimatingPath}
                    onClick={this.handleRepeatPathAnimation}
                    className='button-replay'
                  >
                    <span className='txt-icono'>
                      <Trans i18nKey='REPEAT_ROUTE' />
                    </span>
                  </Button>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
        {!loadingMap && (
          <div className='spinnerMap'>
            <CircularProgress style={COLOR_SPINNER} />
          </div>
        )}
        <div id={CONTAINER_MAP} ref={this.mapHolder} className='Map-holder' />
      </div>
    );
  }
}

export default CentreMap;
